import moment from "moment/moment";
import { useState, useEffect, useReducer } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $, { data } from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../../../layout/MainArea";
import { getDataTableDom, requestToServer } from "../../../utils/appUtils";
import TableDT from "../../../UI/TableDT";
import CardHeaderDashboardOnOff from "../../../utils/cardHeaderDashboardOnOff";
// import { reducerModalWindow } from "./ModalMfS";
import { reducerModalWindow, ModalMfS } from "./ModalMfS";

const ROUTE_URL = "/get_directory_kks_info";

function MfuncStations(props) {
    clearInterval(window.timer)
    const [serverData, setServerData] = useState(null);
    const { showErrorPage, messageBox } = useOutletContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(3);
    const [date, setDate] = useState();
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [onoff, setOnoff] = useState(props.butchState);
    const [paramsModalWindow, dispatchModalWindow] = useReducer(reducerModalWindow, { show: false });

    console.log('paramsModalWindowparamsModalWindow', paramsModalWindow);


    let services = [
        { id: 1, name: "Доступность МС-ЛСН (комплект 1)", status: status, date: date, href: 'msdata' },
        { id: 2, name: "Доступность МС-ЛСН (комплект 2)", status: status, date: date, href: 'msdata' },
        { id: 3, name: "Доступность МС-ЛСН (комплект 3)", status: status, date: date, href: 'msdata' },
        { id: 4, name: "Доступность МС-ЛСН (комплект 4)", status: status, date: date, href: 'msdata' },
    ];

    const handleModal = () => {
        console.log('sdgshbsdfhfdahsd');
        dispatchModalWindow({ type: 'def' });
    };

    const handleAfterDTCreate = ({ DOM, Api }) => {
        dtTable.Api = Api;
        $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlDelete = ROUTE_URL + "/" + data.id;
            messageBox({
                show: true, message: `Вы действительно хотите удалить "${data.name}"?`, mbOk: (e) => {
                    e.preventDefault();
                    messageBox({ show: false, mbOk: null });
                    requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
                }
            });
        });
        $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlStr = "./" + data.id;
            navigate(urlStr);
        });

    }

    useEffect(() => {
        if (onoff) {
            window.timer = setInterval(async () => {
                if (onoff) {
                    requestToServer(ROUTE_URL + "?kks_psats_info_state", { method: 'GET' }, setServerData, showErrorPage, true).then((data) => {
                        console.log();

                        if (data.status === 'normal') {
                            setLoadingStatus(false)
                            setStatus(0)
                            setDate((new Date()).toUTCString());
                        } else if (data.status === 'bad') {
                            setLoadingStatus(false)
                            setStatus(1)
                            setDate((new Date()).toUTCString());
                        } else {
                            setLoadingStatus(false)
                            setStatus(2)
                            setDate((new Date()).toUTCString());
                        }
                    })

                } else {
                    setLoadingStatus(false)
                    setStatus(3)
                    clearInterval(window.timer);
                }
            }, 5000);
        } else {
            clearInterval(window.timer);
        }
    }, [onoff])

    const switchClick = async (event) => {
        if (loading) return;
        setOnoff(!onoff);
        localStorage.setItem('mccon', !onoff)
        if (onoff) {
            setStatus(3);
        } else {
            if (serverData.status === 'normal') {
                setStatus(0)
            } else if (serverData.status === 'bad') {
                setStatus(1)
            } else {
                setStatus(2)
            }
        }
    }

    const handleClick = (event) => {
        if (loading) return;
        event.preventDefault();
        event.target.blur();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    useEffect(() => {
        requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
    }, []);

    const handleCloseModalWindow = (result) => {
        if (result.modalResult === 'mrOk') dtTable.Api.ajax.reload(null, false);
        dispatchModalWindow({ type: 'destroy' });
    };

    console.log('serverData', serverData);

    let styleCard = {};
    let classCard = "card h-100";
    if (props.dashboard === false) {
        styleCard = { minHeight: "var(--clientheight)" };
        classCard = "card border-0";
    }

    if (serverData === null)
        return <MainArea loading />;

    return (
        <>
            <MainArea>
                <MainAreaBody containerClass="">
                    {/* <div className="cont-mfuncbutton">
                        {
                            <div><button type="button" className="btn btn-primary btn-sm btn-our " >Перезапуск аппаратных</button></div>
                        }
                        {
                            <div><button type="button" className="btn btn-primary btn-sm btn-our " >Установка настраиваемых параметров в значение «по умолчанию»</button></div>
                        }
                        {
                            <div><button type="button" className="btn btn-primary btn-sm btn-our " >Самонастройка</button></div>
                        }
                        {
                            <div><button type="button" className="btn btn-primary btn-sm btn-our " >Частотно-кодовое расписание работы аппаратных средств</button></div>
                        }

                    </div> */}

                    <div className={classCard} style={styleCard}>

                        <CardHeaderDashboardOnOff loading={loading} onClick={handleClick} switchClick={switchClick} defCheck={onoff}>
                            Многофункциональные станции
                        </CardHeaderDashboardOnOff>
                        <div className="card-body" style={{ fontSize: "0.85rem" }}>
                            {(
                                services.map((item, index, array) => {
                                    return (
                                        <div key={item.id} className={`gap-2 col-12 d-flex align-items-center ${index == 0 ? "" : "mt-1"}`}>
                                            {loadingStatus ? (
                                                <div style={{ width: "1.25em", height: "1.25em", margin: `6.5px 0px` }} className=" spinner-border spinner-border-sm text-primary"></div>
                                            ) : (
                                                <i className={`fs-5 ${item.status === 0 ? "bi-check-circle-fill text_green" : item.status == 1 ? "bi-x-circle-fill text-danger" : item.status == 2 ? "bi-check-circle-fill text_yellow" : "bi-x-circle-fill text-muted"}`}></i>
                                            )}
                                            <div>
                                                <span className="link-info">
                                                    {<a className="link-info" style={{ cursor: 'pointer' }} onClick={handleModal} >{item.name}</a>} - <small>{moment(item.date).isValid() ? moment.utc(item.date).local().format("DD.MM.YYYY HH:mm:ss") : item.date}</small>
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                    {/* {
                    <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
                } */}
                    {/* <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} /> */}
                </MainAreaBody>
            </MainArea >
            {paramsModalWindow.show && <ModalMfS params={paramsModalWindow} handleClose={handleCloseModalWindow} />}
        </>
    );
}

let dtTable = {
    Api: null,
    getTableOptions: (crud) => {
        const dtOptions = {
            ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
            order: [[0, "asc"]],
            ajax: { type: "GET", url: ROUTE_URL },
            dom: getDataTableDom("lfip", 2, 1),
            columns: [
                // { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
                // { data: "timestamp", title: "Временная метка", render: DataTable.render.datetime(), },
                { data: "name", title: "Наименование", render: DataTable.render.text(), },
                { data: "type", title: "Тип", render: DataTable.render.text(), },
                { data: "description", title: "Описание", render: DataTable.render.text(), },
            ],
        };
        if ((crud & 1) === 0) {
            dtOptions.order = [[0, "asc"]];
            dtOptions.columns.splice(0, 1);
            dtOptions.dom = getDataTableDom("lfip", 1, 1);
        }

        console.log('dtOptions', dtOptions);


        return dtOptions;
    }
};

export default MfuncStations;
