import moment from "moment/moment";
import { useEffect, useState, useReducer } from "react";
import CardHeaderDashboardOnOff from "../../../utils/cardHeaderDashboardOnOff";
import { useNavigate, useOutletContext } from "react-router-dom";
import $, { data } from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../../../layout/MainArea";
import { getDataTableDom, requestToServer } from "../../../utils/appUtils";
import TableDT from "../../../UI/TableDT";
import CardHeaderDashboard from "../../../home/cardHeaderDashboard";
import { reducerModalWindow } from "../../../UI/ModalWindow";
import CreateLSN from "./CreateLSN";

const ROUTE_URL = "/conf_lsn";

const CardLSNConn = (props) => {
    const [serverData, setServerData] = useState(null);
    const { showErrorPage, messageBox } = useOutletContext();
    const navigate = useNavigate();
    const [paramsModalWindow, dispatchModalWindow] = useReducer(reducerModalWindow, { show: false });

    const handleAddRow = () => {
        dispatchModalWindow({ type: 'add' });
    };

    const handleCloseModalWindow = (result) => {
        if (result.modalResult === 'mrOk') dtTable.Api.ajax.reload(null, false);
        dispatchModalWindow({ type: 'destroy' });
    };

    const handleAfterDTCreate = ({ DOM, Api }) => {
        dtTable.Api = Api;
        $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlDelete = ROUTE_URL + "/" + data.id;
            messageBox({
                show: true, message: `Вы действительно хотите удалить "${data.name}"?`, mbOk: (e) => {
                    e.preventDefault();
                    messageBox({ show: false, mbOk: null });
                    requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
                }
            });
        });
        $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            console.log('datadatadatadatadata', data);
            dispatchModalWindow({ type: 'edit', id: data.id });
        });
    }

    useEffect(() => {
        requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
    }, []);

    if (serverData === null)
        return <MainArea loading />;

    return (
        <>
            <MainArea>
                <MainAreaHeader caption="Конфигурирование ЛСН" />
                <MainAreaBody containerClass="">
                    {
                        (serverData.crud & 8) !== 0 && <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
                    }
                    <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
                </MainAreaBody>
            </MainArea>
            {paramsModalWindow.show && <CreateLSN params={paramsModalWindow} handleClose={handleCloseModalWindow} />}
        </>
    );
}

let dtTable = {
    Api: null,
    getTableOptions: (crud) => {
        const dtOptions = {
            ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true, deferRender: true,
            order: [[1, "asc"]],
            ajax: { type: "GET", url: ROUTE_URL },
            dom: getDataTableDom("lfip", 2, 1),
            columns: [
                { orderable: false, searchable: false, data: null, className: "td-btn-action dt-center", render: DataTable.render.btnDelete(), },
                { data: "id", title: "id", render: DataTable.render.number(), },
                { data: "name", title: "Наименование", render: DataTable.render.dataToAnchor("btnShowEdit"), },
                { data: "address", title: "Адрес", render: DataTable.render.text(), },
                { data: "updated_at", title: "Дата обновления", render: DataTable.render.datetime(), },
            ],
        };
        if ((crud & 1) === 0) {
            dtOptions.order = [[0, "asc"]];
            dtOptions.columns.splice(0, 1);
            dtOptions.dom = getDataTableDom("iflp", 1, 1);
        }
        return dtOptions;
    }
}

export default CardLSNConn;