import { useEffect, useRef } from "react";
import $ from "jquery";
import 'datatables.net-bs5'
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'
import 'datatables.net-plugins/api/sum().mjs';

const TableDT = ({ className, getOptions, afterCreate, addFooter }) => {
  const element = useRef();

  useEffect(() => {
    let options = getOptions();
    if (addFooter) {
      let tfoot = '';
      options.columns.forEach((item) => {
        tfoot = tfoot + "<th>" + (item.titleFooter ?? "") + "</th>";
      });
      tfoot = "<tfoot><tr>" + tfoot + "</tr></tfoot>";
      $(element.current).append(tfoot);
    }
    let dtTable = $(element.current).DataTable(options);
    if (afterCreate) afterCreate({ DOM: element.current, Api: dtTable });


    return () => {
      dtTable.destroy();

      $(element.current).off();
      $(element.current).empty();
    };
  }, []);



  return (
    <div>
      <table ref={element} className={className ? "table " + className : "table table-striped"} width="100%"></table>
    </div>
  );
};


export default TableDT;