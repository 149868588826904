import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $, { data } from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../../layout/MainArea";
import { getDataTableDom, requestToServer } from "../../utils/appUtils";
import TableDT from "../../UI/TableDT";

// const ROUTE_URL = "/get_conn_vpy";
const ROUTE_URL = "/get_conn_lsn";

function VpyData(props) {
    clearInterval(window.timer)
    const [serverData, setServerData] = useState(null);
    const { showErrorPage, messageBox } = useOutletContext();
    const navigate = useNavigate();

    const handleAddRow = () => {
        navigate("./create");
    };

    const handleAfterDTCreate = ({ DOM, Api }) => {
        dtTable.Api = Api;
        $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlDelete = ROUTE_URL + "/" + data.id;
            messageBox({
                show: true, message: `Вы действительно хотите удалить "${data.name}"?`, mbOk: (e) => {
                    e.preventDefault();
                    messageBox({ show: false, mbOk: null });
                    requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
                }
            });
        });
        $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlStr = "./" + data.id;
            navigate(urlStr);
        });

    }

    useEffect(() => {
        requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
    }, []);



    console.log('serverData', serverData);

    if (serverData === null)
        return <MainArea loading />;

    return (
        <MainArea>
            <MainAreaHeader caption="Данные ВПУ" />
            <MainAreaBody containerClass="">
                {/* {
                    <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
                } */}
                {/* <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} /> */}
                {
                    <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
                }
            </MainAreaBody>
        </MainArea>
    );
}

let dtTable = {
    Api: null,
    getTableOptions: (crud) => {
        const dtOptions = {
            ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
            order: [[1, "asc"]],
            ajax: { type: "GET", url: ROUTE_URL + "?kks_info" },
            dom: getDataTableDom("lfip", 2, 1),
            columns: [
                { data: "timestamp", title: "Временная метка", render: DataTable.render.datetime(), },
                { data: "type", title: "Тип", render: DataTable.render.text(), },
                { data: "status", title: "Статус", render: DataTable.render.text(), },
                { data: "normal_duration", title: "Время нахождения ЛСН в состоянии «норма»", render: DataTable.render.number(), },
                { data: "normal_psats_count", title: "Количество МС (ЛСН) в состоянии «норма»", render: DataTable.render.number(), },
            ],
        };
        if ((crud & 1) === 0) {
            dtOptions.order = [[0, "asc"]];
            dtOptions.columns.splice(0, 1);
            dtOptions.dom = getDataTableDom("lfip", 1, 1);
        }

        console.log('dtOptions', dtOptions);


        return dtOptions;
    }
};

// let dtTable = {
//     Api: null,
//     getTableOptions: (crud) => {
//         const dtOptions = {
//             ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
//             order: [[1, "asc"]],
//             ajax: { type: "GET", url: ROUTE_URL + "?kks_status" },
//             dom: getDataTableDom("lfip", 2, 1),
//             columns: [
//                 // { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
//                 // { data: "timestamp", title: "Временная метка", render: DataTable.render.datetime(), },
//                 { data: "status", title: "Состояние системы ЛСН", render: DataTable.render.number(), },
//                 { data: "normal_duration", title: "Время нахождения ЛСН в состоянии 'норма'", render: DataTable.render.number(), },
//                 { data: "normal_psats_count", title: "Количество МС в состоянии 'норма'", render: DataTable.render.text(), },
//                 { data: "sensors", title: "Значение датчиков", render: DataTable.render.text(), },
//             ],
//         };
//         if ((crud & 1) === 0) {
//             dtOptions.order = [[0, "asc"]];
//             dtOptions.columns.splice(0, 1);
//             dtOptions.dom = getDataTableDom("lfip", 1, 1);
//         }

//         console.log('dtOptions', dtOptions);


//         return dtOptions;
//     }
// };

export default VpyData;
