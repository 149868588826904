import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import $ from "jquery";
import DataTable from 'datatables.net'
import { getDataTableDom } from '../utils/appUtils'
import TableDT from "../UI/TableDT";
import SelectPeriod from "../UI/SelectPeriod";

const LoginHistory = (props) => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const handleRefreshClick = (event, data) => {
    dtTable.dtParams = { ...data };
    dtTable.Api.ajax.reload();
  }

  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtTable.Api = Api;
    if (props.returnTable) props.returnTable(dtTable.Api);
    $(DOM).on('click', 'tbody a.sys-user-info', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let urlStr = "/sys_users/" + data.user_id;
      navigate(urlStr);
    });
  }

  return (
    <>
      {
        props.type !== "dashboard" && <SelectPeriod loading={loading} refreshClick={handleRefreshClick} params={dtTable.dtParams} />
      }
      <TableDT className={props.type === "dashboard" ? "table-sm table-borderless table-striped" : ""}
        getOptions={() => dtTable.getTableOptions(props.url, props.type, props.setLoading ?? setLoading)} afterCreate={handleAfterDTCreate} />
    </>
  );
}

let dtTable = {
  Api: null,
  dtParams: { dat_start: moment().add(-1, 'months').format("YYYY-MM-DD"), dat_end: moment().format("YYYY-MM-DD"), tz: (new Date()).getTimezoneOffset() },
  getTableOptions: (url, type, setLoading) => {
    const dtOptions =
    {
      ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true, deferRender: true, stateSave: false,
      order: [[0, "desc"]],
      dom: getDataTableDom("lfip", 1, 1),
      ajax: {
        type: "GET", url: url, "data": () => { return dtTable.dtParams },
        "beforeSend": function () { if (setLoading) setLoading(true); },
        "complete": function () { if (setLoading) setLoading(false); },
      },
      columns: [
        {
          "data": 'created_at', "title": "Дата", "className": 'dt-nowrap', "width": "12em",
          "render": DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true)
        },
        {
          "data": 'login', "title": "Логин",
          "render": function (data, type, row) {
            if (type === 'display')
              return '<a class="sys-user-info" href="#" data-user-id="' + row.user_id + '">' + DataTable.render.text().display(row.login) + "</a>";
            else
              return data;
          },
        },
        { "data": 'ip_address', "title": "IP" },
        { "data": 'comment', "title": "Браузер" },
      ],
    };
    if (type === "dashboard") {
      dtOptions.lengthChange = dtOptions.paging = dtOptions.filter = dtOptions.info = dtOptions.processing = false;
      dtOptions.columns.splice(3, 1);
      const item = dtOptions.columns.splice(1, 1)[0]; // получаем элемент, который надо перенести и удаляем его из массива
      dtOptions.columns.push(item);
      dtOptions.scrollY = "470px";
    }
    else if (type !== "login_history")
      dtOptions.columns.splice(1, 1);
    return dtOptions;
  }
}

export default LoginHistory;
