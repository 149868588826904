import { ModalWindow } from "../../../UI/ModalWindow";
import { Input } from '../../../UI/FormElements'

const ROUTE_URL = "/conf_lsn";
const initialData = { name: "", address: "" };

function CreateLSN({ params, handleClose }) {

  let urlSubmit, urlGet;
  if (params.isNew) {
    urlSubmit = ROUTE_URL;
    urlGet = ""; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу
  }
  else {
    urlGet = ROUTE_URL + "/" + params.id;
    urlSubmit = urlGet;
  }
  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <LSNFields />
    </ModalWindow>
  );
}

const LSNFields = ({ dopParams, ...elementProps }) => {

  return (
    <div className="row form-gutter">
      <div className="col-12">
        <Input label="Наименование" name="name" {...elementProps} required autoFocus />
      </div>
      <div className="col-12">
        {/* <Input label="Адрес" name="address" {...elementProps} required /> */}
        <Input label="Адрес" name="address" {...elementProps} required />
      </div>
    </div>
  )
}
export default CreateLSN;
