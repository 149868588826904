import { useState } from 'react'
const CardHeaderDashboardOnOff = ({ loading, onClick, children, switchClick, defCheck, onToggle }) => {
    console.log('defCheck', defCheck);

    return (
        <div className="card-header py-2">
            <div className="align-items-center justify-content-between d-flex">
                <div><h4 className="card-title mb-0">{children}</h4></div>
                {/* 
                <label className="switch">
                    <input className="input" id='checkboxLSN' type="checkbox" defaultChecked={defCheck} defaultValue={defCheck} onChange={switchClick}></input>
                    <span className="slider"></span>
                    <span className="on text">ON</span>
                    <span className="off text">OFF</span>
                </label> */}
                <div className='cont-btn'>
                    <button className={`btn btn-primary btm-sm rounded-pill py-1 px-2 `} title="Переключить" onClick={switchClick} disabled={loading}>
                        {loading ? (
                            <div className="spinner-border spinner-border-sm text-primary"></div>
                        ) : (
                            defCheck ? (
                                <i className="bi-wifi"></i>
                            ) : (
                                <i className="bi-wifi-off"></i>
                            )
                        )}
                    </button>
                    {/* <button className="btn btn-primary btm-sm rounded-pill py-1 px-2" title="Обновить" onClick={onClick} disabled={loading}>
                    <i className="bi-repeat"></i>
                    </button> */}
                </div>
            </div>
        </div>
    );
}
export default CardHeaderDashboardOnOff;