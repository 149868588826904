/*await sleep(<duration>);*/
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const requestToServer = async (url, options, afterCallback, errorCallback, errorAsObject) => {
  let error;
  if (!url.includes(":"))
    url = process.env.REACT_APP_API_URL + url;
  try {
    if (options.body instanceof FormData && options.method?.toUpperCase() === "PATCH") {
      options.method = "POST";
      options.body.append('_method', 'PATCH');
    }
    console.log('options', options);
    let response = await fetch(url, { ...options, headers: { 'Accept': 'application/json', 'Authorization': localStorage.getItem('token') }, });
    console.log(response);
    if (response.status === 200) {
      let json = await response.json();
      if ('errors' in json)
        error = errorAsObject ? Error(JSON.stringify(json.errors)) : json.errors;
      else {
        if (afterCallback) afterCallback(json);
        return json;
      }
    } else {
      if (response.status == 401) {
        localStorage.removeItem('token');
        window.location = "/login";
      }
      if (errorAsObject) {
        response.data = await response.text();
        error = response;
      }
      else
        error = "Ошибка: код ответа " + response.status + " " + response.statusText;
    }
  } catch (exception) {
    error = (errorAsObject ? exception : exception.name + ":" + exception.message);
  }
  if (errorCallback) errorCallback(error);
}

export const getDataTableDom = (dom, mtTopLine, mtBottomLine) => {
  let result = `<"d-flex flex-column justify-content-center flex-sm-row justify-content-sm-between {{mt_topline}}"{{topline}}> 
     <"row"<"col-sm-12 position-relative"tr>> 
     <"d-flex flex-wrap flex-column justify-content-center flex-sm-row justify-content-sm-between {{mt_bottomline}}"{{bottomline}}>`;

  if (dom.substr(0, 1) === 'B') {
    result = 'B' + result;
    dom = dom.substr(1);
  }
  result = result.replace('{{mt_topline}}', (mtTopLine === 0 ? "" : "mt-" + mtTopLine));
  result = result.replace('{{topline}}', dom.substr(0, 2));

  result = result.replace('{{mt_bottomline}}', (mtBottomLine === 0 ? "" : "mt-" + mtBottomLine));
  result = result.replace('{{bottomline}}', dom.substr(2, 2));
  return result;
}

/*
Преобразует если надо ошибки, возвращенные сервером:
 - если входное значение не массив и не объект, то на выходе {_message_: <ошибка>}
 - если topElement не указан, то errors возвращается без изменений  
 - если topElement указан, то происходит перебор всех свойств объекта errors и поиск элементов 
   в topElement с таким же именем и наличием div.invalid-feedback(тут показывается ошибка) и если элемент не найден,
   то эти сообщение накапливаются и возврщаются потом в свойстве _message_  
*/
export function formatServerErorrs(wrapperElement, errors) {
  let notElementErrors = "";
  if (errors === null || errors === undefined) return {};
  if (typeof errors !== "object")
    return { _message_: errors };
  else if (wrapperElement) {
    for (let key in errors) {
      if (wrapperElement.querySelector(`[name="${key}"] ~ div.invalid-feedback`) === null &&
        wrapperElement.querySelector(`[name="${key}"]`)?.closest('div.react-select-container')?.nextElementSibling?.matches(`div.invalid-feedback`) !== true
      ) {
        notElementErrors = notElementErrors + key + ": " + (typeof errors[key] === "object" ? errors[key].toString() : errors[key] + '\n');
      }
    }
  }
  if (notElementErrors !== "")
    errors._message_ = notElementErrors;
  return errors;
}

/* 
Обработчик(onKeyDown) нажатия Enter на элементе внутри формы(<form></form>)
При нажатии Enter на элементе делает активным следующий элемент формы
 */
export function enterChangeFocus(event) {
  if (event.code === 'Enter' || event.code === 'NumpadEnter') {
    event.preventDefault();
    let elements = event.target.form.elements;
    let isFind = false;
    for (let element of elements) {
      if (element == event.target)
        isFind = true;
      else if (isFind && !element.disabled && element.tabIndex >= 0) {
        element.focus();
        break;
      }
    }
  }
}