import { json, Outlet, useLocation } from 'react-router-dom'
import { useCallback, useLayoutEffect, useState } from 'react';
import Menu from './Menu';
import Header from './Header'
import Settings from './Settings';
import ErrorPage from "../ErrorPage.js";
import MessageBox from '../UI/MessageBox'
import { requestToServer } from '../utils/appUtils'
import '../../css/main.css'
import '../../css/main_app.css'

const setAppParams = (settings) => {
  document.body.classList.remove('vertical-menu', 'compact-menu', 'horizontal-menu', 'login');
  document.body.classList.add(settings.menuLayout ?? "vertical-menu");
  document.documentElement.setAttribute('data-bs-theme', settings.themeColor === "dark" ? "dark" : "light");
  document.documentElement.style.setProperty("--primarycolor", settings.primaryColor ?? document.documentElement.style.getPropertyValue("--primarycolor"));
  localStorage.setItem('themeColor', settings.themeColor === "dark" ? "dark" : "light");
}

const Layout = () => {
  const [error, setError] = useState(null);
  const [menu, setMenu] = useState(null);
  const [operator, setOperator] = useState(null);
  const [settings, setSettings] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const [messageBoxData, setMessageBoxData] = useState({
    show: false, caption: "Вопрос", message: "", mbOk: null, mbClose: (e) => {
      setMessageBoxData((messageBoxData) => { return { ...messageBoxData, show: false, mbOk: null } })
    }
  });

  const messageBox = (params) => {
    setMessageBoxData((messageBoxData) => { return { ...messageBoxData, ...params } })
  }

  let currentLocation = useLocation();
  const showErrorPage = (params) => {
    params.key = currentLocation.key;
    setError(params);
  }

  const handleSettingsChange = useCallback((event) => {
    let newSettings;
    setSettings((oldSettings) => {
      if (event.target.dataset.color)
        newSettings = { ...oldSettings, "primaryColor": event.target.dataset.color };
      else
        newSettings = { ...oldSettings, [event.target.name]: event.target.value }
      let formData = new FormData();
      for (let key in newSettings)
        formData.append(key, newSettings[key]);
      requestToServer("/save-settings", { method: 'POST', body: formData }, null, (error) => { alert(JSON.stringify(error)); }, false);
      setAppParams(newSettings);
      return newSettings;
    });
  }, []);

  const onGetData = (data) => {
    // setAppParams(data.params ?? {});
    setSettings(data.params ?? {});
    setMenu(data.menu);
    // setOperator(data.operator);
    setUserInfo(data.user);
    console.log(data);
    localStorage.setItem('user', JSON.stringify(data.user))
  }

  useLayoutEffect(() => {
    requestToServer("/getinitialdata", { method: 'POST' }, onGetData, setError, true);
  }, []);

  if (menu === null)
    return error ? (<ErrorPage errorObject={error} fullScreen />) : (<div className="se-pre-con"><div className="loader"></div></div>);

  if (error && error.key != currentLocation.key) { /*переходим по другой ссылке */
    setError(null);
    return null;
  }

  return (
    <>
      <Header settings={settings} operator={operator} />
      <Menu menu={menu} settings={settings} />
      <main>
        {error ? <ErrorPage errorObject={error} /> : <Outlet context={{ showErrorPage, messageBox, userInfo }} />}
        <MessageBox params={messageBoxData} />
      </main>
      <Settings settings={settings} onChange={handleSettingsChange} />
      <footer className="site-footer">
        © 2022-{(new Date()).getFullYear()} Фиком-ит&trade;
      </footer>
    </>
  );
}

export default Layout;
