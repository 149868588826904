import { useEffect, useState } from "react";
import { Select } from "../UI/FormElements";
import { requestToServer } from "../utils/appUtils";

let monitorTypes = [{ id: "top", name: "Процессы" },{ id: "filesystem", name: "Диски" }, { id: "netstat", name: "Сеть" }, { id: "vmstat", name: "Память" },]

const CardMonitor = (props) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(monitorTypes[0].id);
  const [monitorData, setMonitorData] = useState("");

  const handleClick = (event) => {
    if (loading) return;
    event.preventDefault();
    event.target.blur();
    refreshData();
  }

  const handleOnChangeType = (event) => {
    setType(event.target.value);
  }

  const refreshData = () => {
    let url = new URL(process.env.REACT_APP_API_URL + "/dashboard/monitor");
    url.searchParams.set('type', type);
    setLoading(true);
    requestToServer(url.href, { method: 'GET' }, (response) => {
      setMonitorData(response.data);
      setLoading(false);
    }, (error) => { setLoading(false); console.error('dashboard', error) }, false);
  }

  useEffect(() => {
    refreshData();
  }, []);

  let styleCard = {};
  let classCard = "card h-100";
  if (props.dashboard === false)
  {
    styleCard = { minHeight: "var(--clientheight)" };
    classCard = "card border-0";
  }

  return (
    <div className={classCard} style={styleCard}>
      <div className="card-header py-2">
        <div className="align-items-center justify-content-between d-flex">
          <h4 className="card-title mb-0">Доступность информации</h4>
          {loading && (<div className="spinner-border spinner-border-sm text-primary"></div>)}
          <div className="d-flex">
            <Select className="form-select-sm me-2" value={type} onChange={handleOnChangeType} selectOptions={monitorTypes} />
            <button className="btn btn-primary btm-sm rounded-pill py-1 px-2" title="Обновить" onClick={handleClick} disabled={loading}>
              <i className="bi-repeat"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="card-body d-flex flex-column" style={{ height: "500px" }}>
        <pre className="flex-grow-1 my-0" style={{fontSize:"1em"}}>{monitorData}</pre>
      </div>
    </div>
  );
}

export default CardMonitor;