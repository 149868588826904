import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $ from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../../../layout/MainArea";
import { getDataTableDom, requestToServer } from "../../../utils/appUtils";
import TableDT from "../../../UI/TableDT";
import CardVPYConn from "./cardVPYConn";

function VpylsnConnect(props) {
    clearInterval(window.timer)
    let butchState = localStorage.getItem('vpylsn') === undefined ? true : localStorage.getItem('vpylsn') === "false" ? false : true;
    // const [serverData, setServerData] = useState(null);

    // if (serverData === null) { return <MainArea loading /> }
    // else {}
    return (
        <>
            <MainArea>
                <MainAreaBody>
                    <CardVPYConn dashboard={true} butchState={butchState} />
                </MainAreaBody>
            </MainArea>
        </>
    );

}

export default VpylsnConnect;