import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $ from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../../../layout/MainArea";
import { getDataTableDom, requestToServer } from "../../../utils/appUtils";
import TableDT from "../../../UI/TableDT";
import CardLSNConn from "./cardLSNConn";

function Lsn(props) {
    clearInterval(window.timer)
    let butchState = localStorage.getItem('lsncon') === undefined ? true : localStorage.getItem('lsncon') === "false" ? false : true;

    // if (serverData === null)
    //     return <MainArea loading />;

    return (
        <>
            <MainArea>
                <MainAreaBody>
                    <CardLSNConn dashboard={true} butchState={butchState} />
                </MainAreaBody>
            </MainArea>
        </>
    );

}



export default Lsn;
