import moment from "moment/moment";
import { useEffect, useState } from "react";
import CardHeaderDashboardOnOff from "../../../utils/cardHeaderDashboardOnOff";

const CardVPYConn = (props) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(2);
    const [date, setDate] = useState();
    const [onoff, setOnoff] = useState(props.butchState);
    const [loadingStatus, setLoadingStatus] = useState(true)

    let services = [
        // { id: 1, name: "ВПУ ЛСН", status: status, date: date, href: 'vpydata' },
        { id: 4, name: "ПУ-Ц", status: 1, date: date },
        { id: 5, name: "ПУ-СФД", status: 1, date: date },
        { id: 6, name: "ПУ-ЛСН", status: 1, date: date },
        { id: 7, name: "ПУ-МПС", status: 1, date: date },
        { id: 8, name: "АС-СС", status: 1, date: date },
        { id: 9, name: "КНАП", status: 1, date: date },
        { id: 10, name: "КНАП-П", status: 1, date: date },
        { id: 11, name: "ГАИС", status: 1, date: date },
    ];

    useEffect(() => {
        if (onoff) {
            window.timer = setInterval(() => {
                if (onoff) {
                    setLoadingStatus(false)
                    setStatus(0)
                    setDate((new Date()).toUTCString());
                } else {
                    setLoadingStatus(false)
                    setStatus(2)
                    clearInterval(window.timer);
                }
            }, 5000);
        } else {
            clearInterval(window.timer);
        }
    }, [onoff])

    const switchClick = async (event) => {
        if (loading) return;
        setOnoff(!onoff);
        localStorage.setItem('vpylsn', !onoff)
        if (onoff) {
            setStatus(2);
        } else {
            setStatus(0);
        }
    }

    const handleClick = (event) => {
        if (loading) return;
        event.preventDefault();
        event.target.blur();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    let styleCard = {};
    let classCard = "card h-100";
    if (props.dashboard === false) {
        styleCard = { minHeight: "var(--clientheight)" };
        classCard = "card border-0";
    }

    return (
        <div className={classCard} style={styleCard}>
            <CardHeaderDashboardOnOff loading={loading} onClick={handleClick} switchClick={switchClick} defCheck={onoff}>
                Доступность Компонентов ВПУ ЛСН
            </CardHeaderDashboardOnOff>
            <div className="card-body" style={{ fontSize: "0.85rem" }}>
                {(
                    services.map((item, index, array) => {
                        return (
                            <div key={item.id} className={`gap-2 col-12 d-flex align-items-center ${index == 0 ? "" : "mt-1"}`}>
                                {loadingStatus ? (
                                    <div style={{ width: "1.25em", height: "1.25em", margin: `6.5px 0px` }} className=" spinner-border spinner-border-sm text-primary"></div>
                                ) : (
                                    <i className={`fs-5 ${item.status == 0 ? "bi-check-circle-fill text_green" : item.status == 1 ? "bi-x-circle-fill text-danger" : item.status == 2 ? "bi-check-circle-fill text_yellow" : "bi-x-circle-fill text-muted"}`}></i>
                                )}
                                <div>
                                    <span className="link-info">
                                        {item.href ? <a className="link-info" href={`${item.href}`}>{item.name}</a> : item.name} - <small>{moment(item.date).isValid() ? moment.utc(item.date).local().format("DD.MM.YYYY HH:mm:ss") : item.date}</small>
                                    </span>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
}

export default CardVPYConn;