import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $ from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../../layout/MainArea";
import { getDataTableDom, requestToServer } from "../../utils/appUtils";
import TableDT from "../../UI/TableDT";
import CardSystems from "../../home/cardSystems";

function Lsnstate(props) {
    clearInterval(window.timer)
    // const [serverData, setServerData] = useState(null);

    // if (serverData === null) { return <MainArea loading /> }
    // else {}
    return (
        <>
            <MainArea>
                <MainAreaBody>
                    <CardSystems dashboard={true} />
                </MainAreaBody>
            </MainArea>
        </>
    );


}

export default Lsnstate;