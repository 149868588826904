import { Modal } from 'react-bootstrap';
import React from "react";
import { useState, useEffect } from "react";

const ROUTE_URL = "/conf_lsn";
// const initialData = { name: "", address: "" };
let result = { modalResult: 'mrCancel' };

function ModalMfS({ params, handleClose }) {
    const [show, setShow] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    console.log('paramsparams', params);

    const handleCloseQuery = (event) => {
        if (loading) return;
        result.modalResult = "mrCancel";
        setShow(false);
    }

    const handleExited = () => {
        setLoaded(false);
        setLoading(false);
        handleClose(result);
    }

    const clickLoading = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    let caption = "Просмотр", mode = "R";

    return (
        <Modal show={show} className={`${params.className ?? ""}`} onHide={handleCloseQuery}
            // onExited={handleExited} centered backdrop="static" keyboard={!(loading || !loaded)}>
            onExited={handleExited} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title className="h6">{caption}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb1-1">
                {/* <fieldset disabled={loading || !loaded || !params.show}> */}
                <fieldset className="mfuncbutton-fieldset">
                    {loading ? (<div className="spinner-border spinner-border-sm text-primary mfuncbutton-spinner"></div>) : <div className="cont-mfuncbuttons">
                        {
                            <div className='cont-mfuncbutton'><button type="button" className="btn btn-primary btn-sm btn-our mfuncbutton" onClick={clickLoading} >Перезапуск аппаратных средств</button></div>
                        }
                        {
                            <div className='cont-mfuncbutton'><button type="button" className="btn btn-primary btn-sm btn-our mfuncbutton" onClick={clickLoading} >Сброс параметров по умолчанию</button></div>
                        }
                        {
                            <div className='cont-mfuncbutton'><button type="button" className="btn btn-primary btn-sm btn-our mfuncbutton" onClick={clickLoading} >Проведение расширенного самоконтроля</button></div>
                        }
                        {
                            <div className='cont-mfuncbutton'><button type="button" className="btn btn-primary btn-sm btn-our mfuncbutton" onClick={clickLoading} >Частотно-кодовое расписание работ</button></div>
                        }
                    </div>
                    }
                </fieldset>
                {errors._message_ && <div className="form-error mt-2">{errors._message_}</div>}
            </Modal.Body>
            <Modal.Footer>
                {/* <fieldset disabled={loading || !loaded || !params.show}> */}
                <fieldset>
                    <div>
                        <button type="button" className="btn btn-secondary btn-sm btn-our ms-2" onClick={handleCloseQuery}>Закрыть</button>
                    </div>
                </fieldset>
            </Modal.Footer>
        </Modal >
    );
}

const reducerModalWindow = (state, action) => {
    switch (action.type) {
        case 'def':
            return { ...state, show: true, id: action.id, isNew: false };
        case 'destroy':
            return { ...state, show: false, id: null };
        default:
            return state;
    }
}

export { ModalMfS, reducerModalWindow };
