import moment from "moment/moment";
import { useEffect, useState } from "react";
import CardHeaderDashboardOnOff from "../../../utils/cardHeaderDashboardOnOff";
import { useOutletContext } from "react-router-dom";
import { requestToServer } from "../../../utils/appUtils";

const ROUTE_URL = "/get_state_mc";

const CardMCConn = (props) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(3);
    const [date, setDate] = useState();
    const [onoff, setOnoff] = useState(props.butchState);
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [serverData, setServerData] = useState(null);
    console.log("123serverDataserverData", serverData);

    const { showErrorPage, messageBox } = useOutletContext();
    let services = [
        { id: 1, name: "Доступность МС-ЛСН МС", status: status, date: date, href: 'msdata' },
        { id: 2, name: "Доступность МС-ЛСН (комплект 1)", status: status, date: date, href: 'msdata' },
        { id: 3, name: "Исправность МС-ЛСН (комплект 1)", status: status, date: date, href: 'msdata' },
        { id: 4, name: "Доступность МС-ЛСН (комплект 2)", status: status, date: date, href: 'msdata' },
        { id: 5, name: "Исправность МС-ЛСН (комплект 2)", status: status, date: date, href: 'msdata' },
        { id: 6, name: "Доступность МС-ЛСН (комплект 3)", status: status, date: date, href: 'msdata' },
        { id: 7, name: "Исправность МС-ЛСН (комплект 3)", status: status, date: date, href: 'msdata' },
        { id: 8, name: "Доступность МС-ЛСН (комплект 4)", status: status, date: date, href: 'msdata' },
        { id: 9, name: "Исправность МС-ЛСН (комплект 4)", status: status, date: date, href: 'msdata' },
    ];

    useEffect(() => {
        if (onoff) {
            window.timer = setInterval(async () => {
                if (onoff) {
                    requestToServer(ROUTE_URL + "?kks_psats_info_state", { method: 'GET' }, setServerData, showErrorPage, true).then((data) => {
                        console.log();

                        if (data.status === 'normal') {
                            setLoadingStatus(false)
                            setStatus(0)
                            setDate((new Date()).toUTCString());
                        } else if (data.status === 'bad') {
                            setLoadingStatus(false)
                            setStatus(1)
                            setDate((new Date()).toUTCString());
                        } else {
                            setLoadingStatus(false)
                            setStatus(2)
                            setDate((new Date()).toUTCString());
                        }
                    })

                } else {
                    setLoadingStatus(false)
                    setStatus(3)
                    clearInterval(window.timer);
                }
            }, 5000);
        } else {
            clearInterval(window.timer);
        }
    }, [onoff])

    const switchClick = async (event) => {
        if (loading) return;
        setOnoff(!onoff);
        localStorage.setItem('mccon', !onoff)
        if (onoff) {
            setStatus(3);
        } else {
            if (serverData.status === 'normal') {
                setStatus(0)
            } else if (serverData.status === 'bad') {
                setStatus(1)
            } else {
                setStatus(2)
            }
        }
    }

    const handleClick = (event) => {
        if (loading) return;
        event.preventDefault();
        event.target.blur();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    let styleCard = {};
    let classCard = "card h-100";
    if (props.dashboard === false) {
        styleCard = { minHeight: "var(--clientheight)" };
        classCard = "card border-0";
    }

    return (
        <div className={classCard} style={styleCard}>
            <CardHeaderDashboardOnOff loading={loading} onClick={handleClick} switchClick={switchClick} defCheck={onoff}>
                Доступность Компонентов МС
            </CardHeaderDashboardOnOff>
            <div className="card-body" style={{ fontSize: "0.85rem" }}>
                {(
                    services.map((item, index, array) => {
                        return (
                            <div key={item.id} className={`gap-2 col-12 d-flex align-items-center ${index == 0 ? "" : "mt-1"}`}>
                                {loadingStatus ? (
                                    <div style={{ width: "1.25em", height: "1.25em", margin: `6.5px 0px` }} className=" spinner-border spinner-border-sm text-primary"></div>
                                ) : (
                                    <i className={`fs-5 ${item.status === 0 ? "bi-check-circle-fill text_green" : item.status == 1 ? "bi-x-circle-fill text-danger" : item.status == 2 ? "bi-check-circle-fill text_yellow" : "bi-x-circle-fill text-muted"}`}></i>
                                )}
                                <div>
                                    <span className="link-info">
                                        {item.href ? <a className="link-info" href={`${item.href}`}>{item.name}</a> : item.name} - <small>{moment(item.date).isValid() ? moment.utc(item.date).local().format("DD.MM.YYYY HH:mm:ss") : item.date}</small>
                                    </span>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
}

export default CardMCConn;